<template>
    
    <v-chip
        class="person-label immutable-text"
        small
        label
        :color="isEsedo === false ? 'red lighten-4' : ''"
        @click="produceGlobalEvent"
    >
        <div
            class="if-more-tooltip-chip"
            v-tooltip="{ content: tooltipText }"
        >    
            <v-icon :color="iconColor">{{ icon }}</v-icon>        
            <div :style="{ color: main ? '#E43958' : '' }">{{ Name }}</div>
        </div>
        <div class="pl-actions-wrapper">
            <span
                v-if="canDelete"
                v-tooltip.top="$t('Удалить')"
                @click.stop="onDelete"
            >
                <i class="fas fa-trash dark-gray-color"></i>
            </span>
        </div>
    </v-chip>

</template>

<script>

export default {
    name: "EnterpriseChip",
    props: {
        id: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        formatter: {
            type: Function,
            default: null
        },
        iconColor: {
            type: String,
            default: "#494F59"
        },
        icon: {
            type: String,
            default: "fas fa-landmark"
        },
        main: {
            type: Boolean,
            default: false
        },
        isEsedo: {
            type: Boolean,
            default: true
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        delFunc: {
            type: Function,
            default: null
        }
    },
    computed: {
        withTooltip() {
            return this.name.length > 40;
        },
        Name() {
            if (this.formatter)
                return this.formatter(this.name);

            if (this.name.length > 40)
                return `${this.name.substring(0, 39)}...`;
            else
                return this.name;
        },
        tooltipText() {
            let tooltip = "";

            if (this.withTooltip)
                tooltip += this.name;

            if (this.isEsedo === false)
                tooltip += `${this.withTooltip === true ? '<br/><br/>' : '' }<i>${this.$t('Документ_не_может_быть_отправлен_этому_получателю')}</i>`;

            return tooltip.length ? tooltip : null;
        }
    },
    methods: {
        produceGlobalEvent() {
            this.$eventBus.$emit('show-enterprise-information');
        },
        async onDelete() {
            if (this.id) {
                if (this.delFunc){
                    await this.delFunc(this.id);
                }
                else {
                    this.$notify.confirm(
                        this.$t('Вы_действительно_хотите_удалить_получателя_данного_документа'),
                        async () =>
                        {
                            await this.$store.dispatch('actionsource/deleteRecipient', this.id);
                        }
                    );
                }
            }
        },
    }
}
</script>